import { dateTime } from '@grafana/data';
import { config } from 'app/core/config';

export const isExpired = (): boolean => {
  const { expiry } = config.licenseInfo;
  return !expiry || dateTime(expiry * 1000) < dateTime();
};

export const isInvalid = (): boolean => {
  const { expiry, hasLicense } = config.licenseInfo;
  return hasLicense && !expiry;
};
